html, body {
  height: 100%;
}

html {
  background: radial-gradient(
      40.48% 67.6% at 50% 32.4%,
      #ecf4ff 0%,
      #f7f7ff 52.77%,
      #f7f7f7 100%
    ),
    #f6f7fa;
}

body {
  margin: 0;
  font-family: "JakartaSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 20px;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  min-height: 100%;
}

@font-face {
  font-family: "JakartaSans";
  src: url("./assets/webfonts/PlusJakartaSans-Regular.woff2") format("woff2"),
    url("./assets/webfonts/PlusJakartaSans-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "JakartaSans";
  src: url("./assets/webfonts/PlusJakartaSans-Bold.woff2") format("woff2"),
    url("./assets/webfonts/PlusJakartaSans-Bold.woff") format("woff");
  font-weight: bold;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
